var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-6"},[_c('v-card',{staticClass:"elevation-4 mb-13"},[_c('v-card-text',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"align-self":"center","cols":"9"}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","link":"","outlined":"","to":"/managers/create"}},[_c('v-icon',[_vm._v("mdi-playlist-plus")]),_c('span',{staticClass:"ml-2 body-2 text-normalize"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.create"))+" ")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3","align-self":"center"}},[_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"icon":"","titleparseArrayParams":_vm.$t('global_buttons.reload')},on:{"click":function($event){return _vm.loadData()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-badge',{attrs:{"value":_vm.activeFilters,"content":_vm.activeFilters,"offset-x":"20","offset-y":"14","overlap":"","dot":""}},[_c('v-btn',{staticClass:"mr-2  mt-1",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleSearch()}}},[_c('v-icon',{class:{ 'primary--text': _vm.showSearch },attrs:{"title":_vm.$t('global_buttons.search')}},[_vm._v(" mdi-magnify ")])],1)],1),_c('v-expand-x-transition',[(_vm.showSearch)?_c('div',[_c('v-text-field',{ref:"search",attrs:{"hide-details":"","dense":"","placeholder":_vm.$t('global_buttons.search')},on:{"input":_vm.search},model:{value:(_vm.filter.quick_filter),callback:function ($$v) {_vm.$set(_vm.filter, "quick_filter", $$v)},expression:"filter.quick_filter"}})],1):_vm._e()])],1)],1),_c('v-card',{staticClass:"table-container",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"footer-props":{
            'items-per-page-options': _vm.itemsPerPageOptions
          },"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.size,"loading":_vm.loading,"page":_vm.pagination.number,"search":_vm.queryString,"server-items-length":_vm.totalItems,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"dense":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "size", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "size", $event)},function($event){return _vm.loadData()}],"update:page":[function($event){return _vm.$set(_vm.pagination, "number", $event)},function($event){return _vm.changePage()}],"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.sort, "sortBy", $event)},function($event){return _vm.debouncedLoadData(100)}],"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},function($event){return _vm.debouncedLoadData(100)}]},scopedSlots:_vm._u([_vm._l((_vm.headers),function(item,index){return {key:("header." + (item.value)),fn:function(ref){
          var header = ref.header;
return [_c('span',{key:index},[_vm._v(_vm._s(_vm.$t(header.text)))])]}}}),{key:"item.rating",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.rating)?_c('v-rating',{attrs:{"value":item.rating,"readonly":"","half-increments":"","background-color":"orange lighten-3","color":"orange","small":""}}):_vm._e()],1)]}},{key:"item.avatar",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center my-1"},[_c('v-avatar',[_c('img',{attrs:{"src":item.avatar,"alt":"John"}})])],1)]}},{key:"item.active_clients",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDescription(item, true)}}},[_vm._v(" "+_vm._s(item.active_clients)+" ")])]}},{key:"item.total_clients",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDescription(item)}}},[_vm._v(" "+_vm._s(item.total_clients)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(!item.has_feed_back)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('v-btn',_vm._b({attrs:{"disabled":!item.has_feed_back,"fab":"","text":"","title":"Отзывы о пользователе","x-small":""},on:{"click":function($event){return _vm.loadUserFeedback(item)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(" mdi-playlist-star ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name)+" не имеет отзывов")])]):_c('v-btn',{attrs:{"disabled":!item.has_feed_back,"fab":"","text":"","title":"Отзывы о пользователе","x-small":""},on:{"click":function($event){return _vm.loadUserFeedback(item)}}},[_c('v-icon',[_vm._v(" mdi-playlist-star ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},on:{"click:outside":_vm.clearDescription},model:{value:(_vm.descriptionDialog),callback:function ($$v) {_vm.descriptionDialog=$$v},expression:"descriptionDialog"}},[(_vm.descriptionDialog)?_c('description-table',{attrs:{"manager-id":_vm.selectedManager,"show-active-users":_vm.toShowActiveUsers}}):_vm._e()],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":"70%"},model:{value:(_vm.feedbackDialog),callback:function ($$v) {_vm.feedbackDialog=$$v},expression:"feedbackDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" Оценка пользователей ")]),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-flex align-center ml-5"},on),[_c('v-rating',_vm._b({attrs:{"value":Number(_vm.rating),"background-color":"grey lighten-1","color":"yellow darken-3","dense":"","half-increments":"","hover":"","readonly":""}},'v-rating',attrs,false)),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.rating))])],1)]}}]),model:{value:(_vm.ratingPopup),callback:function ($$v) {_vm.ratingPopup=$$v},expression:"ratingPopup"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-3"},_vm._l(([5, 4, 3, 2, 1]),function(star){return (_vm.starsAmount(star))?_c('div',{key:star,staticClass:"d-flex justify-space-between pt-1"},[_c('v-rating',{staticClass:"d-inline",attrs:{"value":star,"background-color":"grey lighten-1","color":"yellow darken-3","dense":"","half-increments":"","hover":"","readonly":"","small":""}}),_c('span',[_vm._v(_vm._s(_vm.starsAmount(star)))])],1):_vm._e()}),0),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-space-between pa-3"},[_c('span',[_vm._v("Всего отзывов")]),_c('span',[_vm._v(_vm._s(_vm.feedbackList.length))])])],1)],1),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.closeFeedbackDialog()}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"max-height":"70vh","overflow":"auto"}},_vm._l((_vm.feedbackList),function(item,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('div',[_vm._v(" "+_vm._s(item.user)+" ")]),_c('v-scale-transition',{attrs:{"origin":"center"}},[(hover)?_c('v-icon',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                    title: 'Удаление',
                    message: 'Вы уверены что хотите удалить этот отзыв?',
                    callback: function () { return _vm.deleteFeedback(item.id); }
                  }),expression:"{\n                    title: 'Удаление',\n                    message: 'Вы уверены что хотите удалить этот отзыв?',\n                    callback: () => deleteFeedback(item.id)\n                  }"}],staticClass:"opacity-7 ml-3",attrs:{"color":"primary","size":"18"}},[_vm._v("mdi-delete ")]):_vm._e()],1),_c('v-spacer'),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("MM/DD/YYYY, hh:mm"))+" ")])],1),_c('v-card-subtitle',[_c('div',{staticClass:"d-flex align-center"},[_vm._l((5),function(key){return _c('v-icon',{key:key,class:{ 'opacity-2': item.stars < key },attrs:{"color":"warning"}},[_vm._v(" mdi-star ")])}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(item.stars)+".0")])],2)]),_c('v-card-text',[_vm._v(" "+_vm._s(item.message)+" ")]),(index < _vm.feedbackList.length - 1)?_c('v-divider'):_vm._e()],1)]}}],null,true)})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }