
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import { Dictionary } from "vue-router/types/router";

import DescriptionTable from "@/modules/managers/components/DescriptionTable.vue";

export default Vue.extend({
  name: "users",

  mixins: [tablePagination, tableFilters],

  components: {
    DescriptionTable
  },

  data: () => ({
    parseArrayParams,
    headers: [] as Array<HeadersInterface>,
    items: [] as Array<any>,
    filter: {
      quick_filter: ""
    } as Dictionary<string | (string | null)[]>,
    showSearch: false as boolean,
    loading: false as boolean,
    queryString: "" as string,
    selects: {
      roles: [] as Array<SelectComponentInterface>
    },
    password: {
      new: "" as string,
      confirm: "" as string
    } as any,
    shownPassword: {
      new: false as boolean,
      confirm: false as boolean
    } as any,
    selectedUser: {} as any,
    changePasswordDialog: false as boolean,
    feedbackDialog: false as boolean,
    feedbackList: [] as Array<any>,
    ratingPopup: false as boolean,
    rules: {
      required: [
        (v: string): boolean | string =>
          !!v || "Поле обязательно для заполнения!"
      ]
    },
    descriptionDialog: false as boolean,
    toShowActiveUsers: false as boolean,
    selectedManager: null as null | number
  }),

  computed: {
    computedRules(): object {
      return {
        minNumber: this.min
      };
    },
    rating(): string {
      const sum = this.feedbackList.reduce(
        (accumulator: number, item: any) => accumulator + item.stars,
        0
      );

      return (sum / this.feedbackList.length).toFixed(1);
    }
  },

  watch: {
    "$route.query"() {
      this.setFilters(this.$route.query);
      this.applyFilters();
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 20
        }
      };
      const response = await API.manager().getUsers(params);
      const roles = await API.roles().getList();
      next(vm => {
        vm.selects.roles = roles;
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    min(number: number): Function {
      return (v: string): boolean | string =>
        !v || Number(v) >= number || `Минимальное значение ${number}`;
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.manager().getUsers({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });

        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setServerResponse(response: any) {
      this.headers = [
        ...response.headers,
        {
          text: "",
          value: "actions",
          width: "8px",
          align: "center",
          sortable: false
        }
      ];
      this.items = response.items.data;
      this.totalItems = response.items.total;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (newFilters.hasOwnProperty("position_name")) {
        newFilters.position_name = Number(newFilters.position_name);
      }

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
        this.showSearch = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    toggleSearch(): void {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as any).focus();
        });
      }
    },
    search() {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    },
    async loadUserFeedback(user: any): Promise<void> {
      try {
        const response = await this.$API.manager().getFeedbackList({
          manager_id: user.id
        });

        if (!response.length) {
          this.closeFeedbackDialog();
          await this.loadData();
          return;
        }

        this.feedbackList = response;
        this.selectedUser = user;

        this.feedbackDialog = true;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeFeedbackDialog(): void {
      this.feedbackDialog = false;
      this.feedbackList = [];
      this.selectedUser = {};
    },
    async deleteFeedback(id: number): Promise<void> {
      try {
        await this.$API.manager().deleteFeedback({
          id
        });
        await this.$store.dispatch(
          "alert/showSuccess",
          "Отзыв успешно удален!"
        );
        await this.loadUserFeedback(this.selectedUser);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    togglePasswordType(key: string): void {
      this.shownPassword[key] = !this.shownPassword[key];
    },
    starsAmount(stars: number): number {
      return this.feedbackList.filter((item: any) => item.stars === stars)
        .length;
    },
    async showDescription(item: any, showActives?: boolean) {
      if (showActives) {
        if (item.active_clients <= 0) {
          await this.$store.dispatch(
            "alert/showError",
            "Список активных пользователей пуст!"
          );
          return;
        }
        this.toShowActiveUsers = true;
      } else {
        if (item.total_clients <= 0) {
          await this.$store.dispatch(
            "alert/showError",
            "Список пользователей пуст!"
          );
          return;
        }
      }
      this.selectedManager = item.id;
      this.descriptionDialog = true;
    },
    clearDescription() {
      this.selectedManager = null;
      this.toShowActiveUsers = false;
    }
  }
});
