
import Vue from "vue";

export default Vue.extend({
  name: "DescriptionTable",

  props: {
    managerId: {
      type: Number,
      required: true
    },
    showActiveUsers: {
      type: Boolean,
      required: false
    }
  },

  data: () => ({
    items: [] as Array<any>,
    loading: false as boolean
  }),

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<any> {
      this.loading = true;
      try {
        this.items = await this.$API
          .manager()
          .getActiveUsers(this.managerId, this.showActiveUsers);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.loading = false;
    }
  }
});
